import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

// Assets
import logo from "images/logo.png";

// Styles
import styles from "./Navigation.module.scss";

const isActive = (data) => {
  return data.isActive ? styles.active : null;
};

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenuIsOpen = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <Link to="/">
          <img src={logo} alt="Strathmore Therapies" className={styles.logo} />
        </Link>

        <div
          className={
            styles.hamburger + " " + (isMenuOpen ? styles.isActive : "")
          }
          onClick={toggleMenuIsOpen}
        >
          <span className={styles.line}></span>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
        </div>

        <nav className={styles.nav + " " + (isMenuOpen ? styles.open : "")}>
          <ul>
            <li>
              <NavLink to="/" className={isActive} onClick={toggleMenuIsOpen}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={isActive}
                onClick={toggleMenuIsOpen}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/therapies"
                className={isActive}
                onClick={toggleMenuIsOpen}
              >
                Therapies
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={isActive}
                onClick={toggleMenuIsOpen}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Navigation;
