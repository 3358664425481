import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Styles
import styles from "./App.module.scss";

// Components
import Navigation from "./components/navigation/Navigation";
import Loading from "components/loading/Loading";

// Pages
const Home = lazy(() => import("pages/home/Home"));
const About = lazy(() => import("pages/about/About"));
const Therapies = lazy(() => import("pages/therapies/Therapies"));
const Contact = lazy(() => import("pages/contact/Contact"));

function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <Navigation />

        <div className={styles.container}>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/therapies" element={<Therapies />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
